import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import Image from 'Components/Image';
import Button from 'Components/Button';
import s from './Billboard.module.scss';

const Billboard = ({title, text, image, button, alignRight}) => {
    const sizes = [
        '(min-width: 1440px) 679px',
        '(min-width: 768px) 50vw',
        '100vw',
    ];

    const classes = classNames(
        s['Billboard'],
        {[s['Billboard--Right']]: alignRight},
        {[s['Billboard--Left']]: !alignRight},
    );
    return (
        <div className={classes}>
            <div className={s['Billboard__Wrap']}>
                {alignRight &&
                    <div className={s['Billboard__Image']}>
                        <Image {...image} sizes={sizes} />
                    </div>
                }

                <div className={s['Billboard__Content']}>
                    <h2 className={s['Billboard__Title']}>{title}</h2>

                    {!_.isEmpty(text) &&
                        <p className={s['Billboard__Text']}>{text}</p>
                    }

                    {!_.isEmpty(button) &&
                        <Button
                            {...button}
                            className={s['Billboard__Button']}
                            modifier="PrimaryLink"
                        />
                    }
                </div>

                {!alignRight &&
                    <div className={s['Billboard__Image']}>
                        <Image {...image} sizes={sizes} />
                    </div>
                }
            </div>
        </div>
    );
};

Billboard.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    image: PropTypes.object.isRequired,
    button: PropTypes.object,
    alignRight: PropTypes.bool.isRequired,
};

Billboard.defaultProps = {
    title: '',
    text: '',
    image: null,
    button: null,
    alignRight: false,
};

export default Billboard;
